import styles from "./Card.module.scss";
import cl from "classnames";
import { Link } from "react-router-dom";
import arrow from "src/modules/common/assets/img/svg/arrow.svg";

interface ICard {
	title: string;
	description: string;
	image: string;
	className?: string;
	classNameImage?: string;
	onClick?: Function;
	core?: boolean;
}

export const Card: React.FC<ICard> = ({
	title,
	description,
	image,
	className,
	classNameImage,
	core,
}) => {
	const onClick = () => {
		switch (title) {
			case "NO GAS FEES":
				window.open("https://skale.space/");
				return;
			case "BUILD WITH CORE DAO":
				window.open("https://coredao.org/");
				return;
		}
	};

	return (
		<div className={cl(styles.card, className)}>
			<div className={styles.cardTitle}>{title}</div>
			<div className={styles.cardDescription}>{description}</div>
			{core && title === "WHAT WE DO" ? (
				<Link to={"#gameDevelopers"} className={styles.arrow}>
					<div>Learn More</div>
					<img src={arrow} alt="" />
				</Link>
			) : (
				<div className={styles.arrow} onClick={onClick}>
					<div>Learn More</div>
					<img src={arrow} alt="" />
				</div>
			)}
			<img src={image} alt="" className={classNameImage} />
		</div>
	);
};
