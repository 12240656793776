import { Header } from "src/modules/common/ui/Header/Header";
import styles from "./MainPageCore.module.scss";
import { Footer } from "src/modules/common/ui/Footer/Footer";
import { GameDevelopers } from "src/modules/gameDevelopers/ui/GameDevelopers/GameDevelopers";
import { GamingGuild } from "src/modules/gamingGuild/ui/GamingGuild/GamingGuild";
import { LetsPlay } from "src/modules/letsPlay/ui/LetsPlay/LetsPlay";
import { OurGames } from "src/modules/ourGames/ui/OurGames/OurGames";
import { Card } from "src/modules/gamingGuild/ui/Card/Card";
import torch from "src/modules/gamingGuild/assets/img/png/torchCore.png";
import map from "src/modules/gamingGuild/assets/img/png/mapCore.png";
import core from "src/modules/gamingGuild/assets/img/png/core.png";
import { GamingEmpireCore } from "src/modules/gamingEmpire/ui/GamingEmpireCore/GamingEmpireCore";
import superBall from "src/modules/ourGames/assets/img/png/superBall.png";
import superBallLogo from "src/modules/ourGames/assets/img/png/superBallLogo.png";
import trickShot from "src/modules/ourGames/assets/img/png/trickShot.png";
import trickShotLogo from "src/modules/ourGames/assets/img/png/trickShotLogo.png";
import pixudiBackground from "src/modules/ourGames/assets/img/png/pixudiBackgroundCore.png";
import pixudiLogo from "src/modules/ourGames/assets/img/png/pixudiLogo.png";

export const MainPageCore = () => {
	const cards = [
		<Card
			title={"BUILD WITH CORE DAO"}
			description={
				"Enhance your gaming experience with decentralization, scalability, and security provided by Core DAO"
			}
			image={core}
			className={styles.card1}
			classNameImage={styles.core}
			core={true}
		/>,
		<Card
			title={"OUR MISSION"}
			description={
				"We aim to build a strong community of game creators and gamers and drive the use of blockchain technology in gaming."
			}
			image={map}
			className={styles.card2}
			classNameImage={styles.map}
			core={true}
		/>,
		<Card
			title={"WHAT WE DO"}
			description={
				"Our guild was created to help developers on Core DAO create, market and promote their games"
			}
			image={torch}
			className={styles.card3}
			classNameImage={styles.torch}
			core={true}
		/>,
	];

	const games: {
		link: string;
		description: string;
		background: string;
		logo: string;
		type: "pixudi" | "stars" | "dypians" | "superBall" | "trickShot";
		core?: boolean;
	}[] = [
		{
			link: "https://game.pixudi.com/",
			description: "MMORPG board game built on blockchain technology",
			background: pixudiBackground,
			logo: pixudiLogo,
			type: "pixudi",
			core: true,
		},
		{
			link: "https://www.onjoyride.com/world-casual-games#trickshot_blitz",
			description:
				"Stripes or Solids? You choose. Quickly run the table with various trick shots to score big and win in this twist on the classic 8 ball game",
			background: superBall,
			logo: superBallLogo,
			type: "superBall",
			core: true,
		},
		{
			link: "https://www.onjoyride.com/world-casual-games#super_8_ball",
			description:
				"Challenge other players to reach the highest score in a classic game of Trick Shot Pool. Play casually or wager to win $RLY!",
			background: trickShot,
			logo: trickShotLogo,
			type: "trickShot",
			core: true,
		},
	];

	return (
		<div className={styles.content}>
			<div className={styles.top}>
				<Header core={true} />
				<GamingEmpireCore />
			</div>
			<GamingGuild cards={cards} core={true} />
			<div className={styles.bottom}>
				<OurGames games={games} />
			</div>
			<GameDevelopers core={true} />
			<LetsPlay core={true} />
			<Footer core={true} />
		</div>
	);
};
