import styles from "./ButtonDiscord.module.scss";
import borderRound from "src/modules/common/assets/img/png/borderRound.png";
import borderRoundCore from "src/modules/common/assets/img/png/borderRoundCore.png";
import iconX from "src/modules/common/assets/img/svg/iconX.svg";

interface IButtonJoin {
	core?: boolean;
}

export const ButtonDiscord: React.FC<IButtonJoin> = ({ core = false }) => {
	return (
		<div
			className={styles.content}
			onClick={() =>
				core
					? window.open("https://x.com/GamingOnCore")
					: window.open("https://x.com/SkaleGuild")
			}
		>
			<img
				src={core ? borderRoundCore : borderRound}
				alt=""
				className={styles.border}
			/>
			<img src={iconX} alt="" />
		</div>
	);
};
