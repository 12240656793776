import styles from "./GameDevelopers.module.scss";
import cuteMonster from "src/modules/gameDevelopers/assets/img/png/cuteMonster.png";
import cuteMonsterCore from "src/modules/gameDevelopers/assets/img/png/cuteMonsterCore.png";
import cl from "classnames";

interface IGameDevelopers {
	core?: boolean;
}

export const GameDevelopers: React.FC<IGameDevelopers> = ({ core = false }) => {
	return (
		<div className={styles.content}>
			<div className={styles.mainContent}>
				<div className={styles.cards}>
					<div className={styles.card}>
						<div className={styles.cardTitle}>CO-MARKETING</div>
						<div className={styles.cardDescription}>
							Skuild membership includes marketing support, such as social media
							promotion, content creation, and influencer partnerships.
							Cross-marketing with other guild games can also expand reach and
							attract diverse audiences​.
						</div>
					</div>
					<div className={styles.card}>
						<div className={styles.cardTitle}>Development Support</div>
						<div className={styles.cardDescription}>
							We offer development assistance, including tutorials, guides, and
							resources for building on the SKALE blockchain. This support helps
							streamline development processes, reduce costs, and enhance game
							quality.
						</div>
					</div>
					<div className={styles.card}>
						<div className={styles.cardTitle}>Joint Events and Tournaments</div>
						<div className={styles.cardDescription}>
							We will be hosting numerous events and tournaments, providing
							games with additional exposure and engagement opportunities. These
							events can attract new players and retain existing ones by
							creating a competitive and social atmosphere
						</div>
					</div>
					<div className={styles.card}>
						<div className={styles.cardTitle}>
							 Financial Incentives and Grants
						</div>
						<div className={styles.cardDescription}>
							Skuild members can access various financial incentives, including
							grants, play-to-earn scholarships, and staking rewards. These
							incentives can provide additional funding and resources for game
							development and marketing​
						</div>
					</div>
				</div>
				<img
					src={core ? cuteMonsterCore : cuteMonster}
					alt=""
					className={cl(styles.cuteMonster, {
						[styles.cuteMonsterCore]: core,
					})}
				/>
			</div>
		</div>
	);
};
