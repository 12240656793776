import styles from "./GamingEmpireCore.module.scss";
import dragonCore from "src/modules/gamingEmpire/assets/img/png/dragonCore.png";
import coreTitle from "src/modules/common/assets/img/svg/coreTitle.svg";

export const GamingEmpireCore = () => {
	return (
		<div className={styles.content}>
			<div className={styles.mainContent}>
				<div className={styles.titleBlock}>
					<div className={styles.title}>
						WE ARE BUILDING A GAMING EMPIRE TOGETHER ON
					</div>
					<div className={styles.coreBlock}>
						<img src={coreTitle} alt="" />
						<div>CORE</div>
					</div>
				</div>
				<img src={dragonCore} alt="" className={styles.dragon} />
			</div>
		</div>
	);
};
