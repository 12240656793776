import React from "react";
import "./App.css";
import { MainPage } from "../pages/MainPage/MainPage";
import { AppRoutes } from "./routes/AppRoutes";
import { BrowserRouter, HashRouter } from "react-router-dom";
import ScrollToAnchor from "src/modules/common/ui/ScrollToAnchor/ScrollToAnchor";

function App() {
	return (
		<div className="App">
			<BrowserRouter>
				<ScrollToAnchor />
				<AppRoutes />
			</BrowserRouter>
		</div>
	);
}

export default App;
