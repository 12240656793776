import styles from "./Footer.module.scss";
import logo from "src/modules/common/assets/img/svg/logo.svg";
import iconX from "src/modules/common/assets/img/svg/iconX.svg";
import { Link } from "react-router-dom";
import cl from "classnames";

interface IFooter {
	core?: boolean;
}

export const Footer: React.FC<IFooter> = ({ core = false }) => {
	return (
		<div className={styles.wrapper}>
			<div className={cl(styles.content, { [styles.contentCore]: core })}>
				{!core && <img src={logo} alt="" />}
				<Link to={"#gamingGuild"}>ABOUT</Link>
				<Link to={"#gameDevelopers"}>BENEFITS</Link>
				<img src={iconX} alt="" className={styles.discordMobile} />
				<Link to={"#ourGames"}>GAMES</Link>
				{/* <div>BLOG</div> */}
				<div
					onClick={() =>
						core
							? window.open("https://forms.gle/awcxzCvQnygoBAXB7")
							: window.open("https://forms.gle/xesswnXWcqhhZC4g8")
					}
				>
					JOIN
				</div>
				<img
					src={iconX}
					alt=""
					className={styles.discord}
					onClick={() =>
						core
							? window.open("https://x.com/GamingOnCore")
							: window.open("https://x.com/SkaleGuild")
					}
				/>
			</div>
		</div>
	);
};
